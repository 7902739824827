<template>
  <div class="container-xxl my-24">
    <MetaTags
      :title="title"
      :description="description"
    />
    <AppBreadcrumbs
      :links="linksBreadcrumbs"
    />
    <h1 class="fw-bold text-center text-lg-start">
      {{ isVacancy ? 'Вакансии' : 'Кандидаты' }} {{ cityTitle }}<br>
      <span
        v-if="isProfession"
        class="d-block d-lg-none"
      >
        "<span class="text-primary">{{ profession??info?.profession.title }}</span>"
      </span>
    </h1>

    <div class="d-none d-lg-flex justify-content-between align-items-end mb-10">
      <div class="mb-15">
        <div
          v-if="isProfession"
          class="h1 fw-bold d-inline-block me-24"
        >
          "<span class="text-primary">{{ profession??info?.profession.title }}</span>"
        </div>
        <searchCounter
          :per-page="perPage"
          :count="vacancies.count"
          :page="page"
          class="me-5"
        />
        <span>{{ isVacancy ? 'вакансии' : 'резюме' }}</span>
      </div>
      <div class="d-none gap-24">
        <button class="btn d-flex align-items-center fw-medium text-dark py-12 px-25 border border-1 border-platinum rounded-pill shadow-none">
          Сортировка
          <svg
            width="20"
            height="11"
            viewBox="0 0 20 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-inject-url="https://peterdraw.studio/ziro-html/assets/icons/chevron-down.svg"
            class="svg-inject text-primary is-20 ms-15"
          >
            <path d="M19.6273 1.77879L10.6273 9.77878C10.4448 9.9391 10.2102 10.0275 9.96728 10.0275C9.72437 10.0275 9.48977 9.9391 9.30728 9.77878L0.307278 1.77879C0.121445 1.6003 0.0116842 1.35696 0.000880246 1.09952C-0.00992376 0.842077 0.0790634 0.5904 0.249287 0.396964C0.419511 0.203528 0.657833 0.0832638 0.91456 0.0612484C1.17129 0.039233 1.42661 0.117165 1.62728 0.278796L9.96728 7.68878L18.3073 0.278796C18.4046 0.185284 18.5199 0.112452 18.6462 0.0646883C18.7724 0.0169249 18.9071 -0.00478066 19.0419 0.000879482C19.1768 0.00653962 19.3091 0.0394483 19.4309 0.0976224C19.5528 0.155797 19.6615 0.238031 19.7507 0.339371C19.8399 0.440711 19.9076 0.559058 19.9498 0.687279C19.9921 0.8155 20.0079 0.950941 19.9963 1.08544C19.9848 1.21994 19.9462 1.35071 19.8827 1.46987C19.8193 1.58903 19.7324 1.69412 19.6273 1.77879Z" />
          </svg>
        </button>
        <button
          class="btn py-5 px-10 btn-rounded border border-1 border-platinum shadow-none"
          role="button"
        >
          <svg
            height="30"
            width="30"
            class="text-primary"
          >
            <use xlink:href="/public/icons/main.svg#page-info" />
          </svg>
        </button>
      </div>
    </div>

    <div class="d-block d-lg-none text-center mb-24">
      <searchCounter
        :per-page="perPage"
        :count="vacancies.count"
        :page="page"
        class="me-5"
      />
      <span>{{ isVacancy ? 'вакансии' : 'резюме' }}</span>
    </div>

    <div class="row position-relative mb-60">
      <div class="col-12 col-lg-8">
        <AppLoader
          :is-hide="!loading"
          class="w-100"
        />
        <div v-show="!loading">
          <SearchItem
            v-for="(item, index) in vacancies.items"
            :key="item.id"
            :id="item.id"
            :is-vacancy="source !== 2"
            :img="isVacancy ? item.company.logo : item.client.photo"
            :title="getCardTitle(item)"
            :subtitle="getCardSubtitle(item)"
            :salary="item.salary"
            :time="new Date((source === 2 ? item.updated : item.publicationDate) * 1000)"
            :is-active="activeVacancy === index"
            :hide-favorite="hideAction"
            :is-favorite="item.favorite !== null"
            :viewed="searchViewList?.getOne(item.id)"
            @click="openItem(index)"
            @open-click="openItem(index, true)"
            @favorite="favoriteControl(index)"
          >
            <template v-if="source === 1">
              <div
                v-if="item.salary.min !== null"
                class="d-block d-lg-none text-primary"
              >
                <span v-if="item.salary.min !== null">
                  от <span class="fw-bold">{{ item.salary.min.toLocaleString() }}</span>
                </span>
                руб/мес
              </div>
              <div>
                {{ item.experience.title }}
              </div>
              <div
                v-if="item.address !== null"
                class="overflow-hidden text-nowrap"
                style="text-overflow: ellipsis"
              >
                {{ item.address }}
              </div>
              <div
                v-if="item.metroStation.length > 0"
                class="d-flex align-items-center"
              >
                <span
                  class="rounded-circle me-5"
                  style="height: .5em; width: .5em;"
                  :style="{ 'background-color': '#' + item.metroStation[0].line.color }"
                />
                {{ item.metroStation[0].name }}
              </div>
            </template>
            <template v-else>
              <div
                class="overflow-hidden text-nowrap"
                style="text-overflow: ellipsis"
              >
                <span class="fw-bold">Опыт работы в профессии:</span>
                {{ item.experienceInProfession.title }}
              </div>
              <div>
                <span class="fw-bold">Общий стаж:</span>
                {{ totalExperience(item.experience) }}
              </div>
            </template>
          </SearchItem>
        </div>
        <PaginationComponent
          :per-page="perPage"
          :items-count="vacancies.count"
        />
      </div>
      <div
        id="itemInfo"
        class="col-lg-4"
        :class="activeVacancy === null ? null :'bottom-0'"
      >
        <div ref="itemInfoInner">
          <ClientOnly>
            <Teleport
              to="#ItemInfoModal"
              :disabled="isDesktop"
            >
              <div
                class="h-100 bg-white shadow-2 rounded-lg-20 overflow-hidden"
                :style="{ height: wrapHeight === undefined ? undefined : wrapHeight + 'px !important' }"
              >
                <AppLoader
                  :is-hide="!loadingInfo"
                  class="w-100 h-100"
                />
                <div
                  v-if="activeVacancy === null && !loadingInfo"
                  class="h-100 d-flex justify-content-center align-items-center"
                >
                  <span class="fw-bold">Выберете {{ isVacancy ? 'вакансию' : 'резюме' }}</span>
                </div>
                <div
                  v-else
                  class="d-flex flex-column h-100"
                >
                  <div class="h-100">
                    <VideoPlayer
                      :answers="getVideoData"
                      :is-vacancy="isVacancy"
                      :vacancy="isVacancy && itemInfoData !== null ? itemInfoData : undefined"
                      :summary="isVacancy || itemInfoData === null ? undefined : itemInfoData"
                      :wrap-height="wrapHeight"
                      :wrap-width="wrapWidth"
                      @close="() => activeVacancy = null"
                      @request="request"
                    >
                      <template #actionBar>
                        <div v-if="!hideAction">
                          <AppButton
                            class="btn-book flex-shrink-0 me-5"
                            :class="vacancies.items[activeVacancy].favorite === null ? 'border booked' : null"
                            :title="vacancies.items[activeVacancy].favorite === null ? 'Добавить в избранное' : 'Убрать из избранного'"
                            variant
                            is-icon
                            @click="favoriteControl(activeVacancy)"
                          >
                            <svg class="h-100 w-100">
                              <use xlink:href="/public/icons/main.svg#heart-fill" />
                            </svg>
                          </AppButton>
                          <AppButton
                            class="rounded-10"
                            :class="source === 2 || vacancies.items[activeVacancy].response === null ? null : 'btn-book'"
                            :variant="source !== 2 && vacancies.items[activeVacancy].response !== null ? null : BtnVariant.Primary"
                            @click="request"
                          >
                            {{ source === 2 ? 'Пригласить' : (vacancies.items[activeVacancy].response === null ? 'Откликнуться' : 'Отмена') }}
                          </AppButton>
                        </div>
                      </template>
                    </VideoPlayer>
                  </div>
                </div>
              </div>
            </Teleport>
          </ClientOnly>
        </div>
      </div>
    </div>
    <LinkingPopularProfessions
      :city="{
        ...info?.city,
        alias: route.params.city
      }"
      :source="source"
      :profession="route.params.profession"
    />

    <VueFinalModal
      v-model="itemInfoModalShow"
      display-directive="show"
    >
      <div id="ItemInfoModal" />
    </VueFinalModal>

    <LazyInviteModel />
  </div>
</template>

<script setup lang="ts">
import type { Vacancy } from "~/entities/vacancy";
import type { Company } from "~/entities/company";
import type { Profession } from "~/entities/profession";
import { useClient } from "~/stores/client";
import type { VideoData } from "~/entities/videoData";
import { useApiFavorite } from "~/composables/api/favorite";
import { useModal, VueFinalModal } from "vue-final-modal";
import InviteModel from "~/components/modal/inviteModel.vue";
import AuthorizationModal from "~/components/modal/AuthorizationModal.vue";
import MetaTags from "~/components/meta/MetaTags.vue"
import { BtnVariant } from "~/composables/enums/BtnVariant";
import { useCheckMobile } from "~/composables/CheckMobile";
import { useDeclination } from "~/composables/utils/Declination";
import { useApiResponses } from "~/composables/api/responses";
import { SearchSource } from "~/composables/enums/SearchSource";

const { $toast } = useNuxtApp();
const route = useRoute();
const client = useClient();
let apiFavorite = useApiFavorite(client.getClientId!);
const apiResponses = useApiResponses();
const useInviteModal = useModal({
  component: InviteModel,
  attrs: {
    onConfirm() {
      useInviteModal.close();
    }
  }
});
const useAuthorizationModal = useModal({
  component: AuthorizationModal,
  attrs: {
    onConfirm() {
      useAuthorizationModal.close();
    },
    onLogin() {
      apiFavorite = useApiFavorite(client.getClientId);
      getVacancies();
    }
  }
});
const searchViewList = useSearchViewList();

//-----ENTITIES-----\\
type VacancySearch = Vacancy & { company: Company; response: { id: string }|null };
type VacanciesResponse = {
  items: VacancySearch[];
  count: number
};

//-----VARIABLES-----\\
const perPage = 10;
let initialise = false;

//-----STATE-----\\
const searchProfession = useState('searchProfession');
const source = ref<number>(route.params.source === 'vacancies' ? SearchSource.Vacancies : SearchSource.Summaries);
const profession = ref<string|null>(route.query.profession === undefined ? null : route.query.profession);
const vacancies = ref<VacanciesResponse>({ items: [], count: 0 });
const info = ref<{city: City; profession: Profession}|null>(null);
const activeVacancy = ref<number|null>(null);
const itemInfoData = ref(null);
const cityTitle = ref<string>('');
const linksBreadcrumbs = ref<object[]>([]);
const title = ref<string>('');
const description = ref<string>('');
const page = ref<number>(route.query.page === undefined ? 1 : Number(route.query.page));
const isDesktop = ref(false);
const itemInfoModalShow = ref(false);
const itemInfoInner = ref<HTMLDivElement>();
const wrapWidth = ref<number|undefined>(undefined);
const wrapHeight = ref<number|undefined>(undefined);
const loading = ref<boolean>(false);
const loadingInfo = ref<boolean>(false);

//-----COMPUTED-----\\
const isVacancy = computed((): boolean => source.value === 1);
const getVideoData = computed((): VideoData[] => {
  if (itemInfoData.value === null) {
    return [];
  }

  return itemInfoData.value.questions.filter(item => item.answer !== null).map((item) => {
    return {
      src: item.answer.src,
      question: item.question,
      duration: item.answer.duration,
    } as VideoData;
  });
});
const hideAction = computed<boolean>(() => {
  if (!client.isAuthenticated) {
    return false;
  }

  return (isVacancy.value && client.role === 1) || (!isVacancy.value && client.role === 0);
});
const isProfession = computed(() => {
  return profession.value !== null || (info.value !== null && info.value.profession !== undefined);
});

watch(() => route.query, (n, o) => {
  // source.value = Number(route.query.source);
  profession.value = route.query.profession === undefined ? null : route.query.profession;
  page.value = route.query.page === undefined ? 1 : Number(route.query.page);
  getVacancies(!(n.city === o.city && n.source === o.source && n.profession === o.profession && n.page === o.page));
});
const unwatchActiveVacancy = watch(() => activeVacancy.value, () => {
  itemInfoModalShow.value = activeVacancy.value !== null;
});

//-----FETCH-----\\
await getVacancies();

//-----METHODS-----\\
async function getVacancies(newRequest = true) {
  loading.value = true;

  const isProfessionAlias = route.params.profession != '';
  const query = {
    'city': route.params.city,
    'source': source.value,
    'professionAlias': isProfessionAlias ? route.params.profession : undefined,
    'professionTitle': isProfessionAlias ? undefined : route.query.profession,
  };

  let getInfo: boolean = false;
  if (info.value === null || info.value.city.id !== query.city) {
    getInfo = true;
    query['get[city]'] = true;
  }

  query['perPage'] = perPage;
  query['page'] = page.value;
  query['type'] = 'search';

  activeVacancy.value = null;
  if (newRequest) {
    vacancies.value = {};
  } else {
    vacancies.value.items = [];
  }

  let typeTitle = '';
  let { data } = await mainFetch(isVacancy.value ? 'vacancies' : 'summaries', { query });//TODO: ПРОВЕРКА
  data = data.value;
  if (isVacancy.value) {
    title.value = 'Вакансии {city} {profession}: Быстрый поиск работы через видеорезюме';
    description.value = 'Ищете работу {city}? Наша платформа предлагает уникальный способ поиска вакансий{profession} с использованием коротких видео-резюме. Найдите лучшие вакансии и подходящих работодателей здесь';
    typeTitle = 'Вакансии';
    vacancies.value = data.vacancies;
  } else {
    title.value = 'Соискатели {city} {profession}: Эффективный поиск кандидатов';
    description.value = 'Ищете персонал {city}? Наши короткие видео-резюме помогут вам найти подходящих кандидатов{profession} для вашей компании. Упростите и ускорьте процесс найма с нами.';
    typeTitle = 'Кандидаты';
    vacancies.value = data.summaries;
  }

  if (getInfo) {
    info.value = {
      city: data.city,
      profession: data.profession
    };
  }

  if (info.value?.profession !== undefined) {
    searchProfession.value = info.value.profession.title;
  }

  linksBreadcrumbs.value = [];
  cityTitle.value = info.value.city.titleIn ?? info.value.city.title;
  title.value = title.value.replace('{city}', cityTitle.value);
  description.value = description.value.replace('{city}', cityTitle.value);

  linksBreadcrumbs.value.push({
    text: `${typeTitle} ${cityTitle.value}`
  });

  if (isProfession.value) {
    title.value = title.value.replace(' {profession}', ', ' + profession.value??info.value.profession.title.toLocaleLowerCase());
    description.value = description.value.replace('{profession}',' на должность ' + profession.value??info.value.profession.title.toLocaleLowerCase());

    const getParams = [];
    for (const key in route.query) {
      if (key !== 'profession') {
        getParams.push(key + '=' + route.query[key]);
      }
    }

    linksBreadcrumbs.value[0].alias = '/' + route.params.city + '/' + route.params.source;

    linksBreadcrumbs.value.push({
      // text: info.value.profession.title
      text: profession.value??info.value.profession.title
    });

  } else {
    title.value = title.value.replace(' {profession}', '');
    description.value = description.value.replace('{profession}', '');
  }
  loading.value = false;
}
async function openItem(index: number, isOpenBtn: boolean = false) {
  if (activeVacancy.value === index || (!isDesktop.value && !isOpenBtn)) {
    return;
  }
  itemInfoData.value = null;
  activeVacancy.value = index;

  searchViewList!.add(vacancies.value.items[activeVacancy.value].id);
  loadingInfo.value = true;
  let { data } = await mainFetch((isVacancy.value ? 'vacancies/' : 'summaries/') + vacancies.value.items[activeVacancy.value].id, {
    method: 'GET'
  });
  data = data.value;

  if (isVacancy.value) {
    itemInfoData.value = data.vacancy;
  } else {
    itemInfoData.value = data.summary;
  }

  loadingInfo.value = false;
}
async function respond(index: number) {
  apiResponses.add(vacancies.value.items[index].id).then((response) => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code !== 200) {
      $toast.error(response.error.clientMessage ?? response.error.message);
      return;
    }

    vacancies.value.items[index].response = { id: response.response.id }
  });
}
async function cancel(index: number) {
  apiResponses.cancel(vacancies.value.items[index].response.id);
  vacancies.value.items[index].response = null;
}
async function favoriteControl(index: number) {
  if (!client.isAuthenticated) {
    useAuthorizationModal.open();
    return;
  }

  vacancies.value.items[index].favorite;
  if (vacancies.value.items[index].favorite === null) {
    const id: string|null = await apiFavorite.add(vacancies.value.items[index].id as string, source.value === 2 ? 0 : 1);
    if (id !== null) {
      vacancies.value.items[index].favorite = { id };
    }
    return;
  }

  const isAdded: boolean = await apiFavorite.delete(vacancies.value.items[index].favorite.id);
  if (isAdded) {
    vacancies.value.items[index].favorite = null;
  }
}
function openInviteModal() {
  if (activeVacancy.value === null) {
    return;
  }

  useInviteModal.options.attrs.client = vacancies.value.items[activeVacancy.value].client.id;

  useInviteModal.open();
}
function getCardTitle(item: Object) {
  return source.value === 2 ? item.client.name + ' ' + item.client.surname : item.profession.title;
}
function getCardSubtitle(item: Object) {
  return source.value === 2 ? item.professions.map(val => val.title).join(', ') : item.company.name;
}
function request() {
  if (!client.isAuthenticated) {
    useAuthorizationModal.open();
    //$toast.error('Необходимо авторизоваться');
    return;
  }


  if (isVacancy.value) {
    if (client.getActiveCompany?.id !== undefined) {
      $toast.error('Компания не может откликаться на вакансии');
      return;
    }

    if (!client.isSummaryCreated) {
      $toast.error('У вас не создано видеорезюме');
      return;
    }

    if (vacancies.value.items[activeVacancy.value].response === null) {
      respond(activeVacancy.value);
    } else {
      cancel(activeVacancy.value);
    }
  } else {
    if (client.getActiveCompany?.id !== undefined) {
      openInviteModal();
    } else {
      $toast.error('Соискатель не может приглашать кандидатов');
      return;
    }
  }
}
function totalExperience(experience: number): string {
  if (experience === 0) {
    return 'Без опыта';
  }

  return experience + ' ' + useDeclination(experience, 'год', 'года', 'лет');
}
function onResize() {
  if (itemInfoModalShow.value === true) {
    wrapHeight.value = window.innerHeight;
  }
}

onBeforeMount(() => {
  isDesktop.value = !useCheckMobile();
  if (isDesktop.value) {
    unwatchActiveVacancy();
  } else {
    wrapHeight.value = window.innerHeight;
    wrapWidth.value = window.innerWidth;
    window.addEventListener("resize", onResize);
  }
});
onMounted(() => {
  if (isDesktop.value) {
    wrapWidth.value = itemInfoInner.value?.offsetWidth;
    wrapHeight.value = itemInfoInner.value?.offsetHeight;
  }
});
onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});
</script>

<style lang="scss">
@media (min-width: 992px) {
  #itemInfo > div {
    position: sticky;
    height: calc(100vh - 30px) !important;
    top: 15px;
  }
}
</style>
