const MaxItems = 500;

export type ViewItem = {
    id: string,
    created: Date,
};

export const useSearchViewList = () => {
    if (import.meta.server) {
        return undefined;
    }

    // let viewList: string[];
    let viewList: ViewItem[];

    const self = {
        add: (id: string) => {
            const index = self.get().findIndex(item => item.id === id);
            if (index !== -1) {
                viewList.splice(index, 1);
            } else if (self.get().length === MaxItems) {
                viewList.shift();
            }

            viewList.push({
                id,
                created: new Date(),
            });

            localStorage.setItem('search_view_list', JSON.stringify(viewList));
        },
        get: () => {
            if (viewList === undefined) {
                const tmpStorage = localStorage.getItem('search_view_list');
                if (tmpStorage === null) {
                    viewList = [];
                } else {
                    const tmpList = JSON.parse(tmpStorage);
                    if (tmpList.constructor === Array) {
                        viewList = tmpList;
                    } else {
                        viewList = [];
                    }
                }
            }

            return viewList;
        },
        getOne: (id: string) => {
            const item = self.get().find((item) => item.id === id);
            if (item === undefined) {
                return undefined;
            }

            item.created = new Date(item.created);

            return item;
        },
        check: (id: string) => {
            return self.getOne(id) !== undefined;
        },
    };

    return self;
};